let time = Date.now();
let endTime = null;
let totalTime = null;
let paused = true
let delay = 0;

console.log("⏱👷 Timer Worker Inicializado **");

self.onmessage = (msg) => {
  if(msg.data.endTime){
    endTime = msg.data.endTime;
    totalTime = endTime - Date.now();
  }
  if(msg.data.pause){
    paused = true
  }else{
    paused = false
  }
  delay = msg.data.delay || 0
};

function CalcCountdown() {
    let now = Date.now() + delay;
    if (!paused && (endTime != null && time != now)) {
      const remaining = endTime - now;
  
      let timeString = "00:00";
      let timeNormalized = 0;
  
      if (remaining > 0) {      
        timeString = convertMsToTime(remaining);
        timeNormalized = remaining / totalTime;
      }
  
      if (remaining >= 0) {
        self.postMessage({ timeString, timeNormalized });
      }
    }
  
  setTimeout(() => CalcCountdown(), 10);
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function convertMsToTime(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  // let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  // hours = hours % 24;

  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}

CalcCountdown();